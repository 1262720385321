
import {
  LAYOUT_SET_BREADCRUMBS,
  TRACKING_SET_DATA,
} from '~/store/mutation-types';
import pageHelper from '~/utils/page-helper';
import setUrl from '~/utils/set-url';
import getHead from '~/utils/get-head';
import { getEkomiData } from '~/utils/ekomi';
import {
  routerLinks,
  guideLinks,
  articleLinks,
  productLinks,
  contactLinks,
  marketLinks,
  smartButtonLinks,
  advantageLinks,
  bannerLinks,
  ratingsLinks,
  articlesListMarket,
  questionsAnswersLinks,
  accordionLinks,
} from '~/utils/fetch-links';
import getJSONLD from '~/utils/structured-data';
import { getHomepageLabel } from '~/utils/helpers/data';

export default {
  name: 'MarketPage',
  async asyncData(context) {
    try {
      const { $prismic, params, store, app, route } = context;
      const document = await $prismic.api.getByUID(
        'masterpage',
        params.market,
        {
          fetchLinks: [
            ...routerLinks,
            ...guideLinks,
            ...articleLinks,
            ...productLinks,
            ...contactLinks,
            ...marketLinks,
            ...smartButtonLinks,
            ...advantageLinks,
            ...bannerLinks,
            ...ratingsLinks,
            ...articlesListMarket,
            ...questionsAnswersLinks,
            ...accordionLinks,
          ],
        }
      );

      if (document === undefined) {
        return context.error({ statusCode: 404 });
      }

      const { data } = document;
      const ekomiAccount = data.ekomi_account;
      let reviews;

      if (ekomiAccount) {
        reviews = await getEkomiData(ekomiAccount, 'reviews', context);
      }

      const url = setUrl(context, document);
      const slices = await pageHelper(document, context, undefined, {
        withBreadcrumb: true,
      });

      store.commit(`tracking/${TRACKING_SET_DATA}`, {
        market: data.title_tracking || data.title || document.uid || null,
        page_type: document.type,
      });

      store.commit(`layout/${LAYOUT_SET_BREADCRUMBS}`, [
        {
          label: app.i18n.t(getHomepageLabel(route)),
          cta: {
            href: '/',
          },
        },
        {
          label: data.breadcrumb_title || app.i18n.t('breadcrumbs.default'),
        },
      ]);

      return {
        url,
        page: data,
        slices,
        reviews,
        freespeeParameter: data?.freespee_parameter || null,
      };
    } catch (error) {
      context.error(error);
    }
  },
  head() {
    const scripts = this.reviews
      ? [
          getJSONLD('market', {
            market: this.page,
            url: this.url,
            context: this,
            reviews: this.reviews,
          }),
        ]
      : [];

    return getHead(this, scripts, this.freespeeParameter);
  },
};
